<template>
  <div class="manage-card active">
    <div class="prof">
      <img
        v-if="this.$store.state.user.image != null"
        :src="this.$store.state.user.image.small"
        class="prof-img"
        alt=""
      />
      <img
        v-else
        src="../../assets/images/png/pro-img.png"
        class="prof-img"
        alt=""
      />
      <span class="prof-name"
        >{{ $store.state.user.first_name }}
        {{ $store.state.user.last_name }}</span
      >
      <span class="prof-sub">tourist</span>
    </div>
    <div class="prof-links">
      <router-link 
      to="/bookings"
      :class="{'active': this.$route.name=='userBooking'}"
      >
        <img src="../../assets/images/icons/link2.svg" alt="" />{{
          $t("Buyurtmalarim")
        }}
        <span></span>
      </router-link>
      <router-link 
        to="/settings"
        :class="{'active': this.$route.name=='user-settings'}"
      >
        <img src="../../assets/images/icons/link3.svg" alt="" />{{
          $t("Profil")
        }}
        {{ $t("sozlamalari") }}
      </router-link>
      <a @click="exitActive=!exitActive"  class="for-exit">
        <img src="../../assets/images/icons/link4.svg" alt="" />{{
          $t("Chiqish")
        }}
          <div class="exit-card" :class="{'active':exitActive}">
            <span class="e-title">Are you sure to exit</span>  
            <span class="e-sub">Commonly used to demonstrate the or a without relying full.</span> 
            <button @click="logOut()" class="exit-btn">chiqish</button>
            
          </div>
        </a>
    </div>
    <div v-if="exitActive" @click="exitActive=false" class="close-it">

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exitActive : false
    }
  },
  methods: {
    logOut() {
      this.$store.commit("USER_DATA", {});
      this.$router.push({name : 'signIn' });
    },
  },
  mounted(){
    console.log(this.$route);
  }
};
</script>

<style >
.close-it {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.m-taomlar .manage-card .prof-links a.active {
  background: #fff;
}
.manage-card.active{
  
}
</style>