<template>
  <div class="m-taomlar m-buyurtma m-settings t-settings">
    <div class="container">
      <div class="row custom-row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 custom-col2">
          <user-dashboard></user-dashboard>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12 custom-col2">
          <div class="settings-card">
            <form class="prof-form" @submit.prevent="updateUser()">
              <div class="img-sec">
                <div class="prof-av">
                  <label v-if="image == ''" for="userImgUpload" class="av-img">
                    <input
                      accept="image/*"
                      @change="uploadImg($event)"
                      type="file"
                      id="userImgUpload"
                    />
                    <img
                      v-if="this.$store.state.user.image != null"
                      :src="this.$store.state.user.image.small"
                      alt=""
                      class="user-profil-image"
                    />
                    <img
                      v-else
                      src="../../../assets/images/icons/prof.svg"
                      alt=""
                    />
                    <span>{{ $t("Avatar") }}</span>
                  </label>
                  <label v-else for="userImgUpload" class="av-img av-img2">
                    <input
                      @change="uploadImg($event)"
                      type="file"
                      id="userImgUpload"
                    />
                    <img class="user-profil-image" :src="imageUrl" alt="" />
                    <span>{{ $t("Avatar") }}</span>
                  </label>
                  <div class="prof-info">
                    <div class="p-name">
                      {{ formdata.first_name }} {{ formdata.last_name }}
                    </div>
                    <div class="p-sub">tourist</div>
                  </div>
                </div>
                <div class="links">
                  <a href="#!" class="for-delete">Profilni o‘chirish</a>
                </div>
              </div>
              <div class="info-sec">
                <div class="info-cards">
                  <label>{{ $t("Ismingiz ") }}</label>
                  <input type="text" v-model="formdata.first_name" />
                </div>
                <div class="info-cards">
                  <label>{{ $t("Familyangiz") }} </label>
                  <input type="text" v-model="formdata.last_name" />
                </div>
                <div class="info-cards">
                  <label>{{ $t("Email") }} </label>
                  <input type="email" v-model="formdata.email" />
                </div>
                <div class="info-cards">
                  <label>{{ $t("Aloqa telefon raqami") }}</label>
                  <input type="number" v-model="formdata.phone" />
                </div>
                <div class="info-cards info-buttons">
                  <div class="buttons">
                    <input
                      type="button"
                      :value="$t('Bekor qilish')"
                      class="cancel-btn"
                    />
                    <input
                      v-if="!loading"
                      type="submit"
                      :value="$t('Saqlash')"
                      class="more-button save-btn-color"
                    />
                    <loader v-else></loader>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import userDashboard from "../../main/UserDashboard.vue";
export default {
  components: {
    userDashboard,
  },
  data() {
    return {
      image: "",
      imageUrl: "",
      loading: false,
      formdata: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
      },
    };
  },
  methods: {
    uploadImg($event) {
      var file = $event.target.files[0];
      this.image = file;
      var reader = new FileReader();
      var vm = this;
      reader.onload = function (e) {
        vm.imageUrl = e.target.result;
      };
      reader.readAsDataURL(this.image);
    },
    updateUser() {
      this.loading = true;
      axios
        .post("user/update", this.formdata, {
          headers: {
            Authorization: `Bearer ${this.$store.state.user.token}`,
          },
        })
        .then((res) => {
          console.log(res);
          const token = localStorage.getItem("token");
          const form = new FormData();
          form.append("image", this.image);
          axios
            .post("user/upload-image", form, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then(() => {
              this.loading = false;
              axios
                .get("user/get-me", {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  this.$store.commit("USER_DATA", response.data.data);
                  this.$toasted.show("Your profil updated successfuly!", {
                    duration: 2000,
                    type: "",
                    icon: "success-icon",
                  });
                  this.$router.push("/");
                });
            })
            .catch(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        });
    },
  },
  mounted() {
    const token = localStorage.getItem("token");
    if (token != "undefined" && token != null) {
      axios
        .get("user/get-me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.formdata.first_name = response.data.data.first_name;
          this.formdata.last_name = response.data.data.last_name;
          this.formdata.email = response.data.data.email;
          this.formdata.phone = response.data.data.phone;
        });
    }
  },
};
</script>
<style scoped>
.m-settings .settings-card .prof-form .img-sec .prof-av .av-img.av-img2 {
  padding: 0px;
}
.user-profil-image {
  width: 80px;
  border-radius: 24px;
  height: 80px;
}
.m-settings
  .settings-card
  .prof-form
  .info-sec
  .info-buttons
  .buttons
  .save-btn-color {
  background: #fc772c;
}
.m-settings .settings-card .prof-form .info-sec .info-buttons .buttons button {
  margin: 0 15px;
  width: calc(50% - 30px);
  padding: 15px 35px;
  justify-content: center;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #252322;
  background: #f8f8f8;
  border-radius: 12px;
  box-shadow: 0 34px 44px rgb(0 0 0 / 8%);
  transition: 0.3s ease-in-out;
}
.m-settings
  .settings-card
  .prof-form
  .info-sec
  .info-buttons
  .buttons
  .loader-btn {
  background: #fc772c;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.m-taomlar {
  padding-bottom: 120px;
}

</style>
<style >
@media (max-width: 768px) {
  .m-taomlar .manage-card {
    display: block;
  }
}
</style>
